<template>
  <div
    class="flex items-center gap-x-5 p-3 border rounded-4 recording"
    :class="{ 'border-blue-500': isPlaying }"
  >
    <loader :is-loading="isLoading" />
    <div v-show="!isLoading" class="flex items-center w-2/5">
      <div class="flex items-center space-x-3">
        <span
          v-tooltip="
            isPlaying
              ? $t('ACTIVITY.PAUSE_RECORDING')
              : $t('ACTIVITY.PLAY_RECORDING')
          "
          class="button-action"
          data-cy="play-button"
          @click="handlePlayButtonClick"
        >
          <icon-base
            v-bind="icon"
            :data-cy="isPlaying ? 'pause-icon' : 'play-icon'"
            class="cursor-pointer"
            :class="{ 'text-blue-500': isPlaying }"
          />
        </span>
        <div
          class="flex items-center space-x-1 text-label font-semibold text-xs"
        >
          <span ref="timePlayedEl" class="min-w-[37px]">00:00</span>
          <span>/</span>
          <span ref="timeTotalEl" class="min-w-[37px]">00:00</span>
        </div>
        <span
          v-tooltip="$t('COMMON.DOWNLOAD')"
          class="button-action"
          data-cy="download-button"
          @click="downloadFile(recording.file_url, {}, recording.original_name)"
        >
          <icon-base :icon="IconDownload" />
        </span>
        <lf-dropdown
          ref="playbackSpeedDropdownRef"
          v-tooltip="$t('ACTIVITY.PLAYBACK_SPEED')"
          :options="playbackSpeedOptions"
          name="playback-speed"
          data-cy="playback-speed"
          class="speed-select"
          :value="playbackSpeedValue"
          :show-warning-icon="false"
          full-width
          as-badge
          hide-placeholder-when-value
          @change="handlePlaybackSpeedChange"
          @toggle="handlePlayback"
        />
      </div>
    </div>
    <div :id="recordingId" class="call-recording w-3/5" />
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  id: {
    type: [String, Number],
    required: true
  },
  recording: {
    type: Object as PropType<IFile>,
    required: true
  }
});
import { computed, onMounted, ref, type PropType } from "vue";
import WaveSurfer from "wavesurfer.js";
import IconPlay from "@/components/icons/IconPlay.vue";
import IconPause from "@/components/icons/IconPause.vue";
import IconDownload from "@/components/icons/IconDownload.vue";
import LfDropdown from "@/components/ui/inputs/LfDropdown.vue";

import type { IFile } from "@/models/common";
import { downloadFile } from "@/helpers/files";

const playbackSpeedOptions = [
  "0.5x",
  "0.75x",
  "1x",
  "1.25x",
  "1.5x",
  "1.75x",
  "2x"
] as const;

const isPlaying = ref(false);
const isLoading = ref(false);
const timePlayedEl = ref<HTMLElement | null>(null);
const timeTotalEl = ref<HTMLElement | null>(null);
const waveSurfer = ref<WaveSurfer | null>(null);
const playbackSpeedValue = ref<(typeof playbackSpeedOptions)[number]>("1x");
const playbackSpeedDropdownRef = ref<InstanceType<typeof LfDropdown> | null>(
  null
);

const recordingId = computed(() => {
  return `recording-${props.id}`;
});

const icon = computed(() => {
  return {
    icon: isPlaying.value ? IconPause : IconPlay,
    width: 14,
    height: 14
  };
});

const getTimeCodeFromNum = (num: number) => {
  const minutes = Math.floor(num / 60) % 60;
  const seconds = Math.floor(num % 60);
  const secondsWithZero = seconds < 10 ? `0${seconds}` : seconds;
  const minutesWithZero = minutes < 10 ? `0${minutes}` : minutes;
  return `${minutesWithZero}:${secondsWithZero}`;
};

const handlePlayButtonClick = () => {
  isPlaying.value = !isPlaying.value;
  waveSurfer.value?.playPause();
};

const handlePlayback = (isDropdownOpen: boolean) => {
  isPlaying.value = !isDropdownOpen;
  isDropdownOpen ? waveSurfer.value?.pause() : waveSurfer.value?.play();
};

const handlePlaybackSpeedChange = (index: string | number) => {
  playbackSpeedValue.value = playbackSpeedOptions[Number(index)];
  const playbackRate = parseFloat(playbackSpeedValue.value);
  waveSurfer.value?.setPlaybackRate(playbackRate);
  isPlaying.value = true;
  waveSurfer.value?.play();
};

onMounted(() => {
  waveSurfer.value = WaveSurfer.create({
    container: `div #${recordingId.value}`,
    waveColor: "#C2C6CD",
    progressColor: "rgb(55,105,214, 0.7)",
    url: props.recording.file_url,
    barWidth: 4,
    barGap: 2,
    barRadius: 2,
    height: 30,
    mediaControls: false,
    fetchParams: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
      }
    }
  });

  waveSurfer.value?.on("interaction", (newTime: number) => {
    if (!timePlayedEl.value) {
      return;
    }
    timePlayedEl.value.innerText = getTimeCodeFromNum(newTime);
  });
  waveSurfer.value?.on("audioprocess", (newTime: number) => {
    if (!timePlayedEl.value) {
      return;
    }
    timePlayedEl.value.innerText = getTimeCodeFromNum(newTime);
  });

  waveSurfer.value?.on("loading", () => {
    isLoading.value = true;
  });

  waveSurfer.value?.on("ready", () => {
    isLoading.value = false;

    if (!timeTotalEl.value) {
      return;
    }
    timeTotalEl.value.innerText = getTimeCodeFromNum(
      waveSurfer.value?.getDuration() || 0
    );
  });

  waveSurfer.value?.on("finish", () => {
    isPlaying.value = false;
  });
});
</script>
<style scoped>
.button-action {
  @apply flex items-center justify-center p-2 rounded hover:bg-gray-100 cursor-pointer;
}

:deep() {
  .dropdown-option,
  .badge-value {
    @apply text-xs;
  }
}

.speed-select {
  :deep() {
    #exp_elem_list {
      @apply max-h-36;
    }
  }
}
</style>
